import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../../components/modal/success";
import OrganismLoginLayout from "../../components/organism/login-layout";
import {
  generateErrorMessage,
  sendPostRequest,
} from "../../services/request-adapter";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.search.split("&")[0].split("=")[1];
  const token = location.search.split("&")[1].split("=")[1];

  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [input, setInput] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const navigateToLogin = () => {
    setSuccessModal({ visible: false });
    navigate("/login");
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await sendPostRequest(
        `/update-password?email=${email}&token=${token}&password=${values.password}&password_confirmation=${values.password_confirmation}`,
        null
      );
      setSuccessModal({ visible: true });
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <OrganismLoginLayout
        title="Update Password"
        text="Input your new password"
      >
        <Form
          layout="vertical"
          name="udpate-password"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password
              size="large"
              name="password"
              placeholder="Input your new password"
              value={input.password}
              onChange={(e) => handleChange(e)}
            />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            name="password_confirmation"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password
              size="large"
              name="password_confirmation"
              placeholder="Confirm your new password"
              value={input.confirm_password}
              onChange={(e) => handleChange(e)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              loading={loading}
              type="primary"
              htmlType="submit"
              size="large"
              disabled={
                input.password === "" ||
                input.password_confirmation === "" ||
                input.password !== input.password_confirmation
              }
            >
              <b>Submit</b>
            </Button>
          </Form.Item>
        </Form>
      </OrganismLoginLayout>

      <SuccessModal
        open={successModal}
        onOk={navigateToLogin}
        message="Password has been successfully updated, please log back into your account"
      />
    </>
  );
};

export default UpdatePassword;
