import React from "react";
import { Button } from "antd";

const AtomSecondaryButton = (props) => {
  return (
    <Button {...props} className={`${props.className} btn-secondary`}>
      {props.children}
    </Button>
  );
};

export default AtomSecondaryButton;
