import MasteDataTypes from "./types";
const INITIAL_STATE = {
  priceBaseds: [],
  serviceNames: {},
  vehicleTypes: {},
  vehicles: {},
  pools: [],
  loading: {},
  provinces: [],
  cities: [],
  districts: [],
  villages: [],
  drivers: {},
  rates: {},
  rateAmounts: {},
  vendors: {},
  generalServices: {},
  banks: {},
  notifications: {},
  totalUnread: 0,
  driverVendors: {},
};

const masterDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MasteDataTypes.SET_LIST_DRIVER_VENDOR:
      return {
        ...state,
        driverVendors: action.payload,
      };
    case MasteDataTypes.SET_TOTAL_UNREAD_NOTIF:
      return {
        ...state,
        totalUnread: action.payload,
      };
    case MasteDataTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case MasteDataTypes.SET_BANKS:
      return {
        ...state,
        banks: action.payload,
      };
    case MasteDataTypes.SET_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };
    case MasteDataTypes.SET_DRIVERS:
      return {
        ...state,
        drivers: action.payload,
      };
    case MasteDataTypes.SET_VILLAGES:
      return {
        ...state,
        villages: action.payload,
      };
    case MasteDataTypes.SET_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      };
    case MasteDataTypes.SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case MasteDataTypes.SET_PROVICES:
      return {
        ...state,
        provinces: action.payload,
      };
    case MasteDataTypes.SET_POOL:
      return {
        ...state,
        pools: action.payload,
      };
    case MasteDataTypes.SET_PRICE_BASED:
      return {
        ...state,
        priceBaseds: action.payload,
      };
    case MasteDataTypes.SET_SERVICE_NAME:
      return {
        ...state,
        serviceNames: action.payload,
      };
    case MasteDataTypes.SET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };
    case MasteDataTypes.SET_VEHICLE_TYPE:
      return {
        ...state,
        vehicleTypes: action.payload,
      };
    case MasteDataTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MasteDataTypes.SET_RATES:
      return {
        ...state,
        rates: action.payload,
      };
    case MasteDataTypes.SET_RATE_AMOUNTS:
      return {
        ...state,
        rateAmounts: action.payload,
      };
    case MasteDataTypes.SET_GENERAL_SERVICES:
      return {
        ...state,
        generalServices: action.payload,
      };
    default:
      return state;
  }
};

export default masterDataReducer;
