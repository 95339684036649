import { Form, Select } from "antd";
import React from "react";

const { Option } = Select;

const SelectInput = (props) => {
  return (
    <div id={props.name}>
      <Form.Item
        label={
          <span
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "150%",
              color: "#212529",
            }}
          >
            {props.label}
          </span>
        }
        name={props.name}
        rules={[
          {
            required: true,
            message: `Please input ${props.label}!`,
          },
        ]}
      >
        <Select
          getPopupContainer={() => document.getElementById(props.name)}
          {...props}
          placeholder={`Select ${props.label}`}
          size="large"
        >
          {props.data?.length > 0 ? (
            props.data?.map((el, idx) => (
              <Option key={idx} value={el?.id}>
                {el?.display_name ?? el?.name}
              </Option>
            ))
          ) : (
            <Option value="">No Options</Option>
          )}
        </Select>
      </Form.Item>
    </div>
  );
};

export default SelectInput;
