import { Divider, Skeleton, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import infoCircle from "../../assets/icon/info-circle.svg";
import ellipseRed from "../../assets/icon/ellipse-2.svg";
import empty from "../../assets/icon/empty.svg";
import {
  generateErrorMessage,
  sendPatchRequest,
} from "../../services/request-adapter";
import { setLoading } from "../../stores/auth/actions";
import { fetchAllNotifications } from "../../stores/masterData/actions";
import { useNavigate } from "react-router-dom";

const NotificationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifications, loading, totalUnread } = useSelector(
    (state) => state.masterData
  );

  const markAsRead = async (notif) => {
    const { id: idNotif, message } = notif;
    const urlRedirect = message?.data?.url;
    dispatch(setLoading(true));
    navigate(urlRedirect);
    try {
      await sendPatchRequest(`/notifications/${idNotif}`, { read_at: true });
      await dispatch(fetchAllNotifications());
    } catch (error) {
      message(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div
      style={{
        paddingTop: "24px",
        background: "#ffffff",
        boxShadow: "0px 40px 100px rgba(68, 68, 79, 0.1)",
        borderRadius: "10px",
        width: "50vw",
      }}
      gutter={[0, 16]}
    >
      <Typography.Title level={5} style={{ paddingLeft: "24px" }}>
        Notification{" "}
        {totalUnread > 0 && (
          <span
            style={{
              background: "#E23A0F",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderRadius: "100px",
              color: "white",
            }}
          >
            {totalUnread}
          </span>
        )}
      </Typography.Title>
      <Divider
        style={{
          marginBottom: 0,
        }}
      />
      {loading ? (
        <Skeleton className="p-2.5" active />
      ) : (
        <div className="pb-6 overflow-y-scroll relative max-h-[60-vh] no-scrollbar">
          {notifications?.data.length > 0 ? (
            notifications?.data?.map((notif) => {
              return (
                <div
                  className="hover-notif p-2.5 cursor-pointer"
                  onClick={() => markAsRead(notif)}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start items-center">
                      <div className="bg-[#FFF9ED] p-3 rounded-full">
                        <img
                          height={"20px"}
                          src={infoCircle}
                          alt="info-circle"
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <p className="font-semibold text-xs leading-[150%] text-[#212529] m-0">
                          {notif?.message?.title}
                        </p>
                        <p className="text-normal text-[#737373] m-0 w-[50vw]">
                          {notif?.message?.body}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center -mt-5 whitespace-nowrap">
                      <span className="text-[#B9B9B9] text-[10px] mr-5">
                        {notif.created_at}
                      </span>
                      {!notif.read_at && <img src={ellipseRed} alt="dot-red" />}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="p-4">
              <div className="w-full flex flex-col items-center">
                <img className="w-1/3" src={empty} alt="img" />
                <b>There's No Notification</b>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationList;
