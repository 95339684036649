import { Typography } from "antd";
import React from "react";
import superkulLogo from "../../assets/image/superkul-logo.png";

const { Title, Text } = Typography;

const OrganismLoginLayout = ({ title, text, children }) => {
  return (
    <div className="content-center relative">
      <img
        width={214}
        src={superkulLogo}
        alt="error"
        className="absolute top-10"
      />
      <div className="bg-image" />
      <div className="login-form">
        <div className="text-center" style={{ marginBottom: "1rem" }}>
          <Title level={1}>{title}</Title>
          <Text className="text-xs" type="secondary">
            {text}
          </Text>
        </div>
        {children}
      </div>
    </div>
  );
};

export default OrganismLoginLayout;
