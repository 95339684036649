import { message } from "antd";
import axios from "axios";
import { setAuthToken, setLoginStatus } from "./stores/auth/actions";
import { store } from "./stores/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DEV_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Set the AUTH token (put from localStorage) for any request
axiosInstance.interceptors.request.use((config) => {
  const { auth } = store.getState();
  config.headers.Authorization = `Bearer ${auth.token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response)
      if (error.response.status === 401) {
        message.info("Please log back to your account.");
        store.dispatch(setAuthToken(null));
        store.dispatch(setLoginStatus(false));

        window.location = "/login";
      }

    throw error;
  }
);

export default axiosInstance;
