import React from "react";
import img from "../../assets/image/not-found-page.png";

const NotFound = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <img src={img} style={{ width: "100vw" }} alt="img" />
    </div>
  );
};

export default NotFound;
