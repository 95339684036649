import { Form, Input, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import AtomPrimaryButton from "../../button/primary-button";
import AtomSecondaryButton from "../../button/secondary-button";

const UpdatePasswordModal = ({ open, setOpen, isSubmit, onFinish }) => {
  const [form] = Form.useForm();
  const [input, setInput] = useState({
    old_password: "",
    new_password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setInput({ old_password: "", new_password: "", password_confirmation: "" });
    form.setFieldsValue({
      old_password: "",
      new_password: "",
      password_confirmation: "",
    });
  }, [open]);

  return (
    <Modal
      title="Edit Password"
      centered
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Form
        name="update-email"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          style={{
            fontWeight: 500,
            lineHeight: "150%",
            color: "#212529",
          }}
          label="Old Password"
          name="old_password"
          rules={[
            {
              required: true,
              message: "Please input your old password!",
            },
          ]}
        >
          <Input.Password
            size="large"
            name="old_password"
            placeholder="Input your old password"
            onChange={(e) => handleChange(e)}
          />
        </Form.Item>

        <Form.Item
          style={{
            fontWeight: 500,
            lineHeight: "150%",
            color: "#212529",
          }}
          label="New Password"
          name="new_password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password
            size="large"
            name="new_password"
            placeholder="Input your new password"
            onChange={(e) => handleChange(e)}
          />
        </Form.Item>

        <Form.Item
          style={{
            fontWeight: 500,
            lineHeight: "150%",
            color: "#212529",
          }}
          label="Confirm New Password"
          name="password_confirmation"
          rules={[
            {
              required: true,
              message: "Please confirm your new password!",
            },
          ]}
        >
          <Input.Password
            size="large"
            name="password_confirmation"
            placeholder="Confirm your new password"
            onChange={(e) => handleChange(e)}
          />
        </Form.Item>

        <Row justify="center">
          <Space size="middle">
            <AtomPrimaryButton
              loading={isSubmit}
              disabled={
                input.old_password === "" ||
                input.new_password === "" ||
                input.password_confirmation === ""
              }
              htmlType="submit"
            >
              Save Changes
            </AtomPrimaryButton>

            <AtomSecondaryButton onClick={() => setOpen(false)}>
              Cancel
            </AtomSecondaryButton>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
