import { Spin } from "antd";
import React from "react";

const LoadingSpinner = ({ size }) => {
  return (
    <div className="content-center">
      <Spin size={size ?? "default"} />
    </div>
  );
};

export default LoadingSpinner;
