import { Col, Modal, Row, Space } from "antd";
import React from "react";
import AtomPrimaryButton from "../button/primary-button";
import AtomSecondaryButton from "../button/secondary-button";
import infoCircle from "../../assets/icon/info-circle.svg";

const WarningModal = ({ open, setOpen, onOk, message, action, loading }) => {
  return (
    <Modal
      closable={false}
      centered
      open={open.visible}
      footer={null}
      width={400}
    >
      <Row className="text-center" gutter={[0, 16]}>
        <Col span={24}>
          <img className="mx-auto" src={infoCircle} alt="warning" />
        </Col>
        <Col span={24}>
          <b className="mb-4">{message}</b>
        </Col>
        <Col span={24}>
          <Space size="large">
            <AtomPrimaryButton
              danger={action === ", Reject"}
              loading={loading}
              onClick={() => onOk()}
            >
              Yes {action ?? ""}
            </AtomPrimaryButton>
            <AtomSecondaryButton onClick={() => setOpen({ visible: false })}>
              No, Cancel
            </AtomSecondaryButton>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default WarningModal;
