import { Button, Form, Input, message, Row, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrganismLoginLayout from "../../components/organism/login-layout";
import {
  generateErrorMessage,
  sendPostRequest,
} from "../../services/request-adapter";
import {
  getCurrentUser,
  setAuthToken,
  setLoginStatus,
} from "../../stores/auth/actions";

const { Text } = Typography;

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggingIn, setisLoggingIn] = useState(false);
  const [input, setInput] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const onFinish = async (values) => {
    setisLoggingIn(true);
    let params = {
      email: values.email,
      password: values.password,
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SERVICE,
    };

    try {
      const { data } = await sendPostRequest("/login", params);
      if (data?.response_content?.type_user === 4) {
        dispatch(setAuthToken(data?.response_content?.access_token));
        dispatch(setLoginStatus(true));
        // dispatch(setRefreshToken(data.refresh_token));
        dispatch(getCurrentUser());
        navigate("/dashboard", { replace: true });
      } else {
        message.error("You have no right to access this object");
      }
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      setisLoggingIn(false);
    }
  };

  return (
    <OrganismLoginLayout
      title="Welcome Back!"
      text="Please login to access the admin panel"
    >
      <Form
        layout="vertical"
        name="login"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          style={{
            fontWeight: 500,
          }}
          label="Email"
          name="email"
          rules={[
            // { type: "email" },
            {
              required: true,
              message: "Please input your registered email!",
            },
          ]}
        >
          <Input
            size="large"
            name="email"
            autoFocus={true}
            placeholder="Input your registered email"
            value={input.email}
            onChange={(e) => handleChange(e)}
          />
        </Form.Item>

        <Form.Item
          style={{
            fontWeight: 500,
          }}
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your account password!",
            },
          ]}
        >
          <Input.Password
            size="large"
            name="password"
            placeholder="Input your account password"
            value={input.password}
            onChange={(e) => handleChange(e)}
          />
        </Form.Item>

        <Row justify="end" style={{ marginBottom: "1rem" }}>
          <Text
            onClick={() => navigate("/forgot-password")}
            type="secondary"
            className="cursor-pointer text-xs"
          >
            Forgot Password
          </Text>
        </Row>
        <Form.Item>
          <Button
            block
            disabled={input.email === "" || input.password === ""}
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoggingIn}
          >
            <b>Login</b>
          </Button>
        </Form.Item>
      </Form>
    </OrganismLoginLayout>
  );
};

export default LoginPage;
