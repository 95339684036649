import { Button } from "antd";
import React from "react";

const AtomPrimaryButton = (props) => {
  return (
    <Button
      {...props}
      type="primary"
      className={props.className || `btn-default`}
    >
      {props.children}
    </Button>
  );
};

export default AtomPrimaryButton;
