import {
  generateErrorMessage,
  sendGetRequest,
} from "../../services/request-adapter";
import { message } from "antd";
import AuthTypes from "./types";

export const setTitleBreadCumbs = (payload) => ({
  type: AuthTypes.SET_TITLE_BREADCUMBS,
  payload,
});

export const setAuthToken = (payload) => ({
  type: AuthTypes.SET_AUTH_TOKEN,
  payload,
});
export const setCurrentRole = (payload) => ({
  type: AuthTypes.SET_CURRENT_ROLE,
  payload,
});
export const setCurrentUser = (payload) => ({
  type: AuthTypes.SET_CURRENT_USER,
  payload,
});
export const setLoginStatus = (payload) => ({
  type: AuthTypes.SET_LOGIN_STATUS,
  payload,
});
export const setRefreshToken = (payload) => ({
  type: AuthTypes.SET_REFRESH_TOKEN,
  payload,
});
export const setRememberMeStatus = (payload) => ({
  type: AuthTypes.SET_REMEMBER_ME_STATUS,
  payload,
});

export const setLoading = (payload) => ({
  type: AuthTypes.SET_LOADING,
  payload,
});

export const setUserPermissions = (payload) => ({
  type: AuthTypes.SET_PERMISSIONS,
  payload,
});

export const setDeviceToken = (payload) => ({
  type: AuthTypes.SET_DEVICE_TOKEN,
  payload,
});

export const getCurrentUser = () => {
  return async function (dispatch, getState) {
    setLoading(true);
    try {
      const { data } = await sendGetRequest("/profile?include=roles");
      console.log(data.data)
      dispatch(setCurrentUser(data?.data[0] ?? data?.data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
};

export const getUserPermission = () => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/profile/permissions/`);
      dispatch(setUserPermissions(data?.data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
