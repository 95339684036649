import "./App.less";
import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "moment/locale/id";
import { CategoryScale, Chart } from "chart.js";

import { getTokenFcm } from "./config/firebase";

import { useDispatch, useSelector } from "react-redux";

import routes from "./routes";
import OrganismLayout from "./components/organism/layout";
import NotFound from "./pages/auth/not-found";

import LoginPage from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgot-password";
import UpdatePassword from "./pages/auth/update-password";
import LoadingSpinner from "./components/loading-spinner";
import { fetchAllNotifications } from "./stores/masterData/actions";

const App = () => {
  const dispatch = useDispatch();

  const { token, isLoggedIn } = useSelector((state) => state.auth);

  Chart.register(CategoryScale);

  useEffect(() => {
    getTokenFcm();
    token && isLoggedIn && dispatch(fetchAllNotifications());
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner size="large" />}>
      {token && isLoggedIn ? (
        <OrganismLayout>
          <Routes>
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            {routes?.map((el, idx) => (
              <Route key={idx} path={el.path} element={<el.element />} />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </OrganismLayout>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Suspense>
  );
};

export default App;
