const MasteDataTypes = {
  SET_LOADING: "SET_LOADING",
  SET_PRICE_BASED: "SET_PRICE_BASED",
  SET_SERVICE_NAME: "SET_SERVICE_NAME",
  SET_VEHICLE_TYPE: "SET_VEHICLE_TYPE",
  SET_VEHICLES: "SET_VEHICLES",
  SET_POOL: "SET_POOL",
  SET_PROVICES: "SET_PROVICES",
  SET_CITIES: "SET_CITIES",
  SET_DISTRICTS: "SET_DISTRICTS",
  SET_VILLAGES: "SET_VILLAGES",
  SET_DRIVERS: "SET_DRIVERS",
  SET_RATES: "SET_RATES",
  SET_RATE_AMOUNTS: "SET_RATE_AMOUNTS",
  SET_GENERAL_SERVICES: "SET_GENERAL_SERVICES",
  SET_VENDORS: "SET_VENDORS",
  SET_BANKS: "SET_BANKS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_TOTAL_UNREAD_NOTIF: "SET_TOTAL_UNREAD_NOTIF",
  SET_LIST_DRIVER_VENDOR: "SET_LIST_DRIVER_VENDOR",
};

export default MasteDataTypes;
