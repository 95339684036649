import { Col, Modal, Row } from "antd";
import React from "react";
import AtomPrimaryButton from "../button/primary-button";
import tickCircle from "../../assets/icon/tick-circle.svg";

const SuccessModal = ({ open, setOpen, message, customImage, onOk }) => {
  return (
    <Modal
      closable={false}
      centered
      open={open.visible}
      footer={null}
      width={400}
    >
      <Row className="text-center" gutter={[0, 16]}>
        <Col span={24}>
          <img
            className="mx-auto"
            src={customImage ?? tickCircle}
            alt="success"
          />
        </Col>
        <Col span={24}>
          <h3>{message}</h3>
        </Col>
        <Col span={24}>
          <AtomPrimaryButton
            onClick={() => {
              if (onOk) onOk();
              setOpen({ visible: false });
            }}
          >
            Got it
          </AtomPrimaryButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default SuccessModal;
