import {
  sendGetRequest,
  generateErrorMessage,
} from "../../services/request-adapter";
import MasteDataTypes from "./types";
import { message } from "antd";

// SET ACTION
export const setLoading = (payload) => ({
  type: MasteDataTypes.SET_LOADING,
  payload,
});

export const setPriceBased = (payload) => ({
  type: MasteDataTypes.SET_PRICE_BASED,
  payload,
});

export const setServiceName = (payload) => ({
  type: MasteDataTypes.SET_SERVICE_NAME,
  payload,
});

export const setVehicleType = (payload) => ({
  type: MasteDataTypes.SET_VEHICLE_TYPE,
  payload,
});

export const setVehicles = (payload) => ({
  type: MasteDataTypes.SET_VEHICLES,
  payload,
});

export const setPool = (payload) => ({
  type: MasteDataTypes.SET_POOL,
  payload,
});

export const setProvinces = (payload) => ({
  type: MasteDataTypes.SET_PROVICES,
  payload,
});

export const setCities = (payload) => ({
  type: MasteDataTypes.SET_CITIES,
  payload,
});

export const setDistricts = (payload) => ({
  type: MasteDataTypes.SET_DISTRICTS,
  payload,
});

export const setVillages = (payload) => ({
  type: MasteDataTypes.SET_VILLAGES,
  payload,
});

export const setAllDrivers = (payload) => ({
  type: MasteDataTypes.SET_DRIVERS,
  payload,
});

export const setAllRates = (payload) => ({
  type: MasteDataTypes.SET_RATES,
  payload,
});

export const setAllRateAmounts = (payload) => ({
  type: MasteDataTypes.SET_RATE_AMOUNTS,
  payload,
});

export const setAllVendors = (payload) => ({
  type: MasteDataTypes.SET_VENDORS,
  payload,
});

export const setAllGeneralServices = (payload) => ({
  type: MasteDataTypes.SET_GENERAL_SERVICES,
  payload,
});

export const setAllBanks = (payload) => ({
  type: MasteDataTypes.SET_BANKS,
  payload,
});

export const setNotifications = (payload) => ({
  type: MasteDataTypes.SET_NOTIFICATIONS,
  payload,
});

export const setTotalUnreadNotif = (payload) => ({
  type: MasteDataTypes.SET_TOTAL_UNREAD_NOTIF,
  payload,
});

export const setDriverVendors = (payload) => ({
  type: MasteDataTypes.SET_LIST_DRIVER_VENDOR,
  payload,
});
//FETCH ACTION

export const fetchAllDriverVendors = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/driver-vendors${params || ""}`);
      dispatch(setDriverVendors(data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllNotifications = () => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      let tempTotal = 0;
      const { data } = await sendGetRequest(`/notifications`);
      data.data.map((el) => {
        if (!el.read_at) {
          tempTotal++;
        }
      });
      dispatch(setNotifications(data));
      dispatch(setTotalUnreadNotif(tempTotal));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllBanks = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/channel-codes${params || ""}`);
      dispatch(setAllBanks(data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllVendors = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/vendors${params || ""}`);
      dispatch(setAllVendors(data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllDrivers = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/drivers${params || ""}`);
      dispatch(setAllDrivers(data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllPriceBased = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(
        `/price-based-services${params || ""}`
      );
      dispatch(setPriceBased(data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllServiceName = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/service-names${params || ""}`);
      dispatch(setServiceName(data));
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllVehicleType = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/vehicle-types${params || ""}`);
      dispatch(setVehicleType(data));
    } catch (error) {
      console.log(error);
      // message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllPool = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/pools${params || ""}`);
      dispatch(setPool(data));
    } catch (error) {
      console.log(error);
      // message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllProvinces = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(
        `/indonesia/provinces${params || ""}`
      );
      dispatch(setProvinces(data));
    } catch (error) {
      console.log(error);
      // message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchCitiesByProvince = (prov, params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(
        `/indonesia/provinces/${prov}/cities${params || ""}`
      );
      dispatch(setCities(data));
    } catch (error) {
      console.log(error);
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchDistrictsByCity = (city, params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(
        `/indonesia/cities/${city}/districts${params || ""}`
      );
      dispatch(setDistricts(data));
    } catch (error) {
      console.log(error);
      // message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchVillagesByDistrict = (district, params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(
        `/indonesia/districts/${district}/villages${params || ""}`
      );
      dispatch(setVillages(data));
    } catch (error) {
      console.log(error);
      // message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllVehicles = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/vehicles${params || ""}`);
      dispatch(setVehicles(data));
    } catch (error) {
      console.log(error);
      // message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllRates = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(`/driver-rates${params || ""}`);
      dispatch(setAllRates(data));
    } catch (error) {
      console.log(error);
      //message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAllGeneralServices = (params) => {
  return async function (dispatch, getState) {
    dispatch(setLoading(true));
    try {
      const { data } = await sendGetRequest(
        `/general-services?page=${params || ""}&search=name:;status:1`
      );
      dispatch(setAllGeneralServices(data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
