import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Image,
  Layout,
  Menu,
  message,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";

import btnEdit from "../../assets/icon/edit-2.svg";
import lock from "../../assets/icon/lock.svg";
import notification from "../../assets/icon/notification.svg";
import cards from "../../assets/icon/cards.svg";
import dashboard from "../../assets/icon/element-4.svg";
import note from "../../assets/icon/note.svg";
import routing from "../../assets/icon/routing.svg";
import userSquare from "../../assets/icon/user-square.svg";
import categoryService from "../../assets/icon/category-service.svg";
import superkul from "../../assets/image/superkul-logo.png";
import avatar from "../../assets/image/avatar.png";
import ellipseRed from "../../assets/icon/ellipse-2.svg";

import WarningModal from "../../components/modal/warning";
import SuccessModal from "../../components/modal/success";

import {
  getCurrentUser,
  setAuthToken,
  setCurrentUser,
  setLoginStatus,
  setRefreshToken,
  setTitleBreadCumbs,
} from "../../stores/auth/actions";

import { useDispatch, useSelector } from "react-redux";
import {
  generateErrorMessage,
  sendDeleteRequest,
  sendGetRequest,
  sendPatchRequest,
  sendPostMultipartRequest,
} from "../../services/request-adapter";
import EditProfile from "../modal/profile/edit-profile";

// import ProfileModal from "../../components/modal/profile/profile";
import UpdatePasswordModal from "../../components/modal/profile/update-password";
import { async } from "@firebase/util";
import NotificationList from "../modal/notification-list";
import { setLoading } from "../../stores/masterData/actions";

const { Title, Text } = Typography;
const { Sider } = Layout;

const OrganismLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const { user, titleBreadCumbs } = useSelector((state) => state.auth);
  const { notifications, loading, totalUnread } = useSelector(
    (state) => state.masterData
  );

  const user_detail = user?.profile;

  const [autoAssign, setAutoAssign] = useState(false);
  const [disableAutoAssignModal, setDisableAutoAssignModal] = useState({
    visible: false,
  });
  const [logoutModal, setLogoutModal] = useState({ visible: false });

  const [profileImage, setProfileImage] = useState(null);
  const [profileModal, setProfileModal] = useState({ visible: false });

  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [successModal, setSuccessModal] = useState({ visible: false });
  const [hasDetail, setHasDetail] = useState(false);
  const [editProfile, setEditProfile] = useState({ visible: false });
  const [isAutoAccept, setIsAutoAccept] = useState(0);

  const menus = [
    {
      name: "Dashboard",
      icon: dashboard,
      link: "/dashboard",
    },
    {
      name: "Manage Order",
      icon: note,
      children: [
        { name: "Customer Order", link: "/customer-order" },
        { name: "Drop", link: "/drop" },
      ],
    },
    {
      name: "Manage Trip",
      icon: routing,
      link: "/manage-trip",
    },
    {
      name: "Manage Driver",
      icon: userSquare,
      link: "/list-driver",
      children: [{ name: "List Driver", link: "/list-driver" }],
    },
    {
      name: "Manage Service",
      icon: categoryService,
      link: "/manage-service",
    },
    {
      name: "My Wallet",
      icon: cards,
      link: "/my-wallet",
    },
  ].map((menu) => {
    return {
      key: menu.link?.concat(".", menu.name),
      icon: <img alt="icon-sidebar" src={menu.icon} />,
      label: menu.name,
      children: menu.children?.map((el) => ({
        key: el.link?.concat(".", el.name, "#", menu.name),
        label: el.name,
      })),
    };
  });

  const fetchVendorDetail = async () => {
    console.log(user)
    try {
      const { data } = await sendGetRequest(`/vendors/${user?.profile?.id}`);
      setIsAutoAccept(data?.data?.available_status);
    } catch (error) {
      message.error(generateErrorMessage(error));
    }
  };

  const handleLogout = async () => {
    try {
      await sendDeleteRequest("/logout");

      navigate("/login");
      setLogoutModal({ visible: false });

      dispatch(setAuthToken(null));
      dispatch(setLoginStatus(false));
      dispatch(setRefreshToken(null));
    } catch (error) {
      message.error(generateErrorMessage(error));
    }
  };

  const handleChangeSwitch = async () => {
    if (isAutoAccept === 1) {
      setDisableAutoAssignModal({ visible: true });
    } else {
      try {
        const { data } = await sendPostMultipartRequest(
          `/vendors/${user?.profile?.id}`,
          { available_status: isAutoAccept === 1 ? 0 : 1 }
        );
        setSuccessModal({
          visible: true,
          message: `Auto Accept has been successfully ${
            isAutoAccept ? "deactivate" : "reactivated"
          }`,
        });
        fetchVendorDetail();
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const handleAutoAccept = async () => {
    try {
      const { data } = await sendPostMultipartRequest(
        `/vendors/${user?.profile?.id}`,
        { available_status: isAutoAccept === 1 ? 0 : 1 }
      );
      setDisableAutoAssignModal({ visible: false });

      setSuccessModal({
        visible: true,
        message: `Auto Accept has been successfully ${
          isAutoAccept ? "deactivate" : "reactivated"
        }`,
      });
      fetchVendorDetail();
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updatePassword = async (values) => {
    let params = {
      old_password: values.old_password,
      new_password: values.new_password,
      new_password_confirmation: values.password_confirmation,
    };

    setIsSubmit(true);
    try {
      await sendPatchRequest("/users/password", params);

      setUpdatePasswordModal(false);

      setSuccessModal({
        visible: true,
        message: "Password has been successfully changed.",
      });
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      setIsSubmit(false);
    }
  };

  const openMenu = (e) => {
    const pathKey = e.key;
    const linkKey = pathKey.split(".")[0];
    const titleKey = pathKey.split(".")[1];

    const splitTitleKey = titleKey.split("#");

    const parentKey =
      splitTitleKey.length > 1 ? splitTitleKey[1] : splitTitleKey[0];

    const childKey = splitTitleKey.length > 1 && splitTitleKey[0];

    dispatch(
      setTitleBreadCumbs({
        titleParent: parentKey,
        titleChild: childKey,
        link: linkKey,
      })
    );
    navigate(e.key.split(".")[0]);
  };

  const onEditProfile = async (values) => {
    dispatch(setLoading(true));
    try {
      await sendPostMultipartRequest("users/vendor", values);
      setEditProfile({ visible: false });
      dispatch(getCurrentUser());
      setSuccessModal({
        visible: true,
        message: "Profile successfully updated",
      });
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const Profile = () => {
    return (
      <Row
        style={{
          padding: "24px",
          background: "#ffffff",
          boxShadow: "0px 40px 100px rgba(68, 68, 79, 0.1)",
          borderRadius: "10px",
          width: "365px",
          height: "395px",
        }}
        gutter={[0, 16]}
      >
        <Col span={24}>
          <Title level={5}>Profile</Title>
        </Col>
        <Col span={24}>
          <div
            style={{
              height: "1px",
              background: "#F3F3F3",
              borderRadius: "1px",
            }}
          />
        </Col>
        <Col
          onClick={() => setProfileModal({ type: "profile", visible: true })}
          span={7}
          className="relative cursor-pointer"
        >
          <Link to={`/profile`}>
            <Image
              style={{ borderRadius: "100%", marginRight: "24px" }}
              width={70}
              height={70}
              src={user_detail?.profile_img_link}
              fallback={avatar}
              preview={false}
            />
          </Link>
          <div
            className="absolute"
            style={{
              background: "#ffffff",
              borderRadius: "100%",
              width: 30,
              height: 30,
              boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.25)",
              left: 40,
              top: 40,
              display: "flex",
              alignItems: "center",
              padding: "5px",
              gap: "16px",
            }}
          >
            <img
              onClick={() => setEditProfile({ visible: true })}
              src={btnEdit}
              alt="btn"
            />
          </div>
        </Col>
        <Col span={17}>
          <Space direction="vertical">
            <Link to={`/profile`}>
              <Title level={5}>
                {user_detail?.first_name} {user_detail?.last_name ?? ""}
              </Title>
            </Link>
            <Text>{user?.email}</Text>
          </Space>
        </Col>
        <Col span={22} onClick={() => setUpdatePasswordModal(true)}>
          <b className="cursor-pointer hover:opacity-50">Edit Password</b>
        </Col>
        <Col span={2}>
          <img src={lock} alt="lock" />
        </Col>
        <Col span={24}>
          <Button
            block
            danger
            type="primary"
            size="large"
            onClick={() => setLogoutModal({ visible: true })}
          >
            Logout
          </Button>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    dispatch(getCurrentUser());
    // fetchVendorDetail();
    location.pathname.split("/").filter((el) => {
      if (el === "detail") {
        setHasDetail(true);
      } else {
        setHasDetail(false);
      }
    });
    if (location.pathname === "/" || location.pathname === "/dashboard") {
      dispatch(
        setTitleBreadCumbs({
          link: "/dashboard",
          titleParent: "Dashboard",
          titleChild: "Dashboard",
        })
      );
    }
  }, [location]);

  return (
    <Layout>
      <Sider width={220} theme="light" className="h-screen overflow-auto">
        <Row
          justify="center"
          align="middle"
          className="p-4"
          style={{ marginBottom: "1rem" }}
        >
          <img height={40} width={152} src={superkul} alt="error" />
        </Row>
        <Menu
          mode="inline"
          items={menus}
          onClick={openMenu}
          defaultSelectedKeys={[
            `${titleBreadCumbs?.link}.${titleBreadCumbs?.titleParent}`,
          ]}
        />
      </Sider>

      <Layout className="h-screen overflow-auto">
        <Row
          justify="space-between"
          style={{
            backgroundColor: "#FCFCFD",
            padding: "1rem 2rem",
          }}
        >
          <Col>
            <Title level={5}>{titleBreadCumbs?.titleParent}</Title>
            {titleBreadCumbs?.titleParent !== "Dashboard" && (
              <Breadcrumb className="text-xs">
                <Breadcrumb.Item>
                  {titleBreadCumbs?.titleParent}
                </Breadcrumb.Item>
                {titleBreadCumbs?.titleChild && (
                  <Breadcrumb.Item>
                    <Link
                      className={hasDetail ? "" : "text-primary"}
                      to={titleBreadCumbs?.link}
                    >
                      {titleBreadCumbs.titleChild}
                    </Link>
                  </Breadcrumb.Item>
                )}
                {hasDetail && (
                  <Breadcrumb.Item>
                    <span className="text-primary">Detail</span>
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            )}
          </Col>

          <Row align="middle">
            <Space size="large">
              <Space className="">
                <span className="mr-2">Auto Accept</span>
                <Switch
                  checked={isAutoAccept}
                  onChange={handleChangeSwitch}
                  style={{
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
                  }}
                />
              </Space>
              <Dropdown trigger={["click"]} overlay={<NotificationList />}>
                <div style={{ position: "relative" }}>
                  {totalUnread > 0 && (
                    <img
                      style={{
                        position: "absolute",
                        right: 2,
                      }}
                      src={ellipseRed}
                      alt="dot-red"
                    />
                  )}
                  <img
                    src={notification}
                    alt="bell"
                    className="cursor-pointer"
                  />
                </div>
              </Dropdown>
              <Dropdown overlay={<Profile />}>
                <img
                  className="pointer"
                  alt="profile-img"
                  style={{ borderRadius: "100%" }}
                  width={50}
                  height={50}
                  src={user_detail?.profile_img_link ?? avatar}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
        <div style={{ padding: "1rem 2rem" }}>{children}</div>
      </Layout>

      <EditProfile
        title="Edit User"
        loading={loading}
        userDetail={user_detail}
        open={editProfile}
        setOpen={setEditProfile}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        isSubmit={isSubmit}
        onFinish={onEditProfile}
      />

      {/* <UpdateEmailModal
        open={updateEmailModal}
        setOpen={setUpdateEmailModal}
        isSubmit={isSubmit}
        onFinish={onFinish}
      /> */}

      <UpdatePasswordModal
        open={updatePasswordModal}
        setOpen={setUpdatePasswordModal}
        isSubmit={isSubmit}
        onFinish={updatePassword}
      />

      <WarningModal
        open={logoutModal}
        setOpen={setLogoutModal}
        onOk={handleLogout}
        message="Do you want logout from this account?"
      />

      <WarningModal
        open={disableAutoAssignModal}
        setOpen={setDisableAutoAssignModal}
        onOk={handleAutoAccept}
        message={`Are you sure you disable "Auto Accept"?`}
      />

      <SuccessModal
        open={successModal}
        setOpen={setSuccessModal}
        message={successModal.message}
      />
    </Layout>
  );
};

export default OrganismLayout;
