import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Col, Form, Input, message, Modal, Row, Skeleton, Space } from "antd";

import AtomPrimaryButton from "../../button/primary-button";
import AtomSecondaryButton from "../../button/secondary-button";
import SelectInput from "../../input/input-select";
import InputText from "../../input/input-text";
import AtomUploadImage from "../../input/upload-image";
import {
  fetchAllBanks,
  fetchAllProvinces,
  fetchCitiesByProvince,
  fetchDistrictsByCity,
} from "../../../stores/masterData/actions";

const EditProfile = ({
  title,
  loading,
  userDetail,
  open,
  setOpen,
  profileImage,
  setProfileImage,
  isSubmit,
  onFinish,
}) => {
  const { banks, provinces, cities, districts } = useSelector(
    (state) => state.masterData
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    channel_code: "",
    account_name: "",
    account_number: "",
    address: "",
    province_id: "",
    city_id: "",
    district_id: "",
    postal_code: "",
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const [previewUrl, setPreviewUrl] = useState(
    userDetail?.profile_img_link ?? ""
  );

  const handleChangeInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (open.visible) {
      (async () => {
        dispatch(fetchAllProvinces(`?searchJoin=and&search=status:1&limit=0`));
        dispatch(fetchAllBanks(`?searchJoin=and&search=status:1&limit=0`));
        selectProvince(userDetail?.village?.province_id);
        selectCity(userDetail?.village?.city_id);
        setInput({
          first_name: userDetail.first_name,
          last_name: userDetail.last_name,
          phone_number: userDetail.phone_number,
          channel_code: userDetail.channel_code,
          account_name: userDetail.account_name,
          account_number: userDetail.account_number,
          address: userDetail.address,
          province_id: userDetail?.village?.province_id.toString(),
          city_id: userDetail?.village?.city_id.toString(),
          district_id: userDetail?.village?.district_id.toString(),
          postal_code: userDetail.postal_code,
        });
        form.setFieldsValue({
          first_name: userDetail.first_name,
          last_name: userDetail.last_name,
          phone_number: userDetail.phone_number,
          channel_code: userDetail.channel_code,
          account_name: userDetail.account_name,
          account_number: userDetail.account_number,
          address: userDetail.address,
          province_id: userDetail?.village?.province_id.toString(),
          city_id: userDetail?.village?.city_id.toString(),
          district_id: userDetail?.village?.district_id.toString(),
          postal_code: userDetail.postal_code,
        });
      })();
    }
  }, [open]);

  const selectProvince = (provId) => {
    dispatch(
      fetchCitiesByProvince(provId, `?searchJoin=and&search=status:1&limit=0`)
    );
    form.setFieldsValue({
      city_id: null,
      district_id: null,
    });
    setInput({
      ...input,
      province_id: provId,
      city_id: null,
      district_id: null,
    });
  };
  const selectCity = (cityId) => {
    dispatch(
      fetchDistrictsByCity(cityId, `?searchJoin=and&search=status:1&limit=0`)
    );
    form.setFieldsValue({
      district_id: null,
    });
    setInput({
      ...input,
      city_id: cityId,
      district_id: null,
    });
  };

  const onSubmit = (value) => {
    onFinish(value);
  };

  //   useEffect(() => {
  //     const controller = new AbortController();

  //     if (open.id) {
  //       (async () => {
  //         setIsLoading(true);
  //         try {
  //           const { data } = await sendGetRequest(`corporates/${open.id}`, {
  //             signal: controller.signal,
  //           });
  //           const res = data.data;
  //           setPreviewUrl(res?.profile_img_link);

  //           setProvinceId(res.province_id);
  //           setCityId(res.city_id);
  //           setDistrictId(res.district_id);
  //           setSubDistrictId(res.village_id);
  //           form.setFieldsValue({
  //             email: res.email,
  //             first_name: res.first_name,
  //             last_name: res.last_name,
  //             phone_number: res.phone_number,
  //             organization: res.organization,
  //             address: res.address,
  //             min_order: res.min_order,
  //             attachment: res.attachment,
  //             payment_type: res.payment_type,
  //             province: res.province_id,
  //             city: res.city_id,
  //             district: res.district_id,
  //             village_id: res.village_name,
  //             postal_code: res.postal_code,
  //           });
  //         } catch (error) {
  //           message.error(generateErrorMessage(error));
  //         } finally {
  //           setIsLoading(false);
  //         }
  //       })();
  //     } else {
  //       form.resetFields();
  //       setInput({
  //         first_name: "",
  //         last_name: "",
  //         email: "",
  //         password: "",
  //         phone_number: "",
  //         organization: "",
  //         address: "",
  //         postal_code: "",
  //         min_order: "",
  //       });
  //       if (setFile) setFile(null);
  //       // setProfileImage(null);
  //       setProvinceId("");
  //       setCityId("");
  //       setDistrictId("");
  //       setSubDistrictId("");
  //       setPaymentTypeId("");
  //     }

  //     return () => {
  //       controller.abort();
  //     };
  //   }, [open.id]);

  //   useEffect(() => {
  //     const controller = new AbortController();

  //     (async () => {
  //       setIsLoading(true);
  //       try {
  //         const { data } = await sendGetRequest("/indonesia/provinces?limit=0", {
  //           signal: controller.signal,
  //         });
  //         setProvinces(data.data);
  //       } catch (error) {
  //         message.error(generateErrorMessage(error));
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     })();

  //     return () => {
  //       controller.abort();
  //     };
  //   }, []);

  //   useEffect(() => {
  //     const controller = new AbortController();

  //     if (provinceId) {
  //       (async () => {
  //         setIsLoading(true);
  //         try {
  //           const { data } = await sendGetRequest(
  //             `/indonesia/provinces/${provinceId}/cities?limit=0`,
  //             {
  //               signal: controller.signal,
  //             }
  //           );
  //           setCities(data.data);
  //         } catch (error) {
  //           message.error(generateErrorMessage(error));
  //         } finally {
  //           setIsLoading(false);
  //         }

  //         return () => {
  //           controller.abort();
  //         };
  //       })();
  //     }

  //     if (cityId) {
  //       (async () => {
  //         setIsLoading(true);
  //         try {
  //           const { data } = await sendGetRequest(
  //             `/indonesia/cities/${cityId}/districts?limit=0`,
  //             {
  //               signal: controller.signal,
  //             }
  //           );
  //           setDistrict(data.data);
  //         } catch (error) {
  //           message.error(generateErrorMessage(error));
  //         } finally {
  //           setIsLoading(false);
  //         }

  //         return () => {
  //           controller.abort();
  //         };
  //       })();
  //     }

  //     if (districtId) {
  //       (async () => {
  //         setIsLoading(true);
  //         try {
  //           const { data } = await sendGetRequest(
  //             `/indonesia/districts/${districtId}/villages?limit=0`,
  //             {
  //               signal: controller.signal,
  //             }
  //           );
  //           setSubDistrict(data.data);
  //         } catch (error) {
  //           message.error(generateErrorMessage(error));
  //         } finally {
  //           setIsLoading(false);
  //         }

  //         return () => {
  //           controller.abort();
  //         };
  //       })();
  //     }

  //     return () => {
  //       controller.abort();
  //     };
  //   }, [provinceId, cityId, districtId]);

  //   const payment_types = [
  //     // { name: "Pre Paid", id: 0 },
  //     { name: "Post Paid", id: 1 },
  //   ];

  return (
    <Modal
      title={title}
      centered
      open={open.visible}
      onCancel={() => setOpen({ visible: false })}
      footer={
        <>
          <Row justify="center">
            <Space size="middle">
              <AtomPrimaryButton
                disabled={
                  !input.first_name ||
                  !input.last_name ||
                  !input.phone_number ||
                  !input.address ||
                  !input.province_id ||
                  !input.city_id ||
                  !input.district_id ||
                  !input.postal_code
                }
                loading={isSubmit}
                onClick={() => {
                  form.submit();
                }}
              >
                Save
              </AtomPrimaryButton>

              <AtomSecondaryButton onClick={() => setOpen({ visible: false })}>
                Cancel
              </AtomSecondaryButton>
            </Space>
          </Row>
        </>
      }
      width={620}
      bodyStyle={{
        overflowY: "scroll",
        maxHeight: "80vh",
        position: "relative",
      }}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          name="profile"
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
        >
          <Row gutter={[16, 16]}>
            <Col span={7}>
              <AtomUploadImage
                image={profileImage}
                setImage={setProfileImage}
                previewUrl={previewUrl}
                isEdit={open.id || userDetail}
              />
            </Col>
            <Col span={17}></Col>
            <Col span={12}>
              <InputText
                label="First Name PIC"
                name="first_name"
                placeholder="first name"
                message="first name"
                value={input.first_name}
                onChange={(e) => handleChangeInput(e)}
              />
            </Col>
            <Col span={12}>
              <InputText
                label="Last Name PIC"
                name="last_name"
                placeholder="last name"
                message="last name"
                value={input.last_name}
                onChange={(e) => handleChangeInput(e)}
              />
            </Col>
            <Col span={24}>
              <InputText
                label="PIC Phone Number"
                name="phone_number"
                placeholder="Phone Number"
                message="Phone Number"
                value={input.phone_number}
                onChange={(e) => handleChangeInput(e)}
              />
            </Col>
            <Col span={8}>
              <SelectInput
                label="Bank Account"
                name="channel_code"
                data={banks?.data}
                value={input.channel_code}
                disabled
              />
            </Col>
            <Col span={8}>
              <InputText
                label="Account Name"
                name="account_name"
                placeholder="Account Name"
                message="Account Name"
                value={input.account_name}
                disabled
              />
            </Col>
            <Col span={8}>
              <InputText
                label="Account Number"
                name="account_number"
                placeholder="Account Number"
                message="Account Number"
                value={input.account_number}
                disabled
              />
            </Col>
            <Col span={24}>
              <InputText
                label="Address"
                name="address"
                placeholder="Address"
                message="Address"
                value={input.address}
                onChange={(e) => handleChangeInput(e)}
              />
            </Col>
            <Col span={12}>
              <SelectInput
                label="Province"
                name="province_id"
                data={provinces?.data}
                value={input.province_id}
                onChange={(e) => selectProvince(e)}
              />
            </Col>
            <Col span={12}>
              <SelectInput
                label="City/District"
                name="city_id"
                data={cities?.data}
                value={input.city_id}
                onChange={(e) => selectCity(e)}
              />
            </Col>
            <Col span={12}>
              <SelectInput
                label="Sub District"
                name="district_id"
                data={districts?.data}
                value={input.district_id}
                onChange={(e) => setInput({ ...input, district_id: e })}
              />
            </Col>
            <Col span={12}>
              <InputText
                label="Postal Code"
                name="postal_code"
                placeholder="Postal Code"
                message="Postal Code"
                value={input.postal_code}
                onChange={(e) => handleChangeInput(e)}
              />
            </Col>
            {/* <Col span={24}>
              <Form.Item
                style={{
                  fontWeight: 500,
                }}
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your old password!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  name="old_password"
                  placeholder="Input your old password"
                  value={input.old_password}
                  onChange={(e) => handleChangeInput(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{
                  fontWeight: 500,
                }}
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  name="new_password"
                  placeholder="Input your new password"
                  value={input.new_password}
                  onChange={(e) => handleChangeInput(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{
                  fontWeight: 500,
                }}
                label="Confirm New Password"
                name="confirm_new_password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  name="confirm_new_password"
                  placeholder="Confirm new password"
                  value={input.confirm_new_password}
                  onChange={(e) => handleChangeInput(e)}
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default EditProfile;
