import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../../components/modal/success";
import OrganismLoginLayout from "../../components/organism/login-layout";
import {
  generateErrorMessage,
  sendPostRequest,
} from "../../services/request-adapter";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState({ visible: false });

  const onFinish = async () => {
    setLoading(true);
    let params = {
      email: email,
    };
    try {
      await sendPostRequest("/password/forgot", params);
      setSuccessModal({ visible: true });
    } catch (error) {
      message.error(generateErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const navigateToLogin = () => {
    setSuccessModal({ visible: false });
    navigate("/login");
  };

  return (
    <>
      <OrganismLoginLayout
        title="Forgot Password"
        text="Input your registered email"
      >
        <Form
          layout="vertical"
          name="forgot-password"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: "#212529",
                }}
              >
                Email
              </span>
            }
            name="email"
            rules={[
              { type: "email" },
              {
                required: true,
                message: "Please input your registered email!",
              },
            ]}
          >
            <Input
              size="large"
              autoFocus={true}
              placeholder="Input your registered email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              disabled={email === ""}
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
            >
              <b>Submit</b>
            </Button>
          </Form.Item>
        </Form>
      </OrganismLoginLayout>

      <SuccessModal
        open={successModal}
        message="We have sent a password update link to your email address"
        onOk={navigateToLogin}
      />
    </>
  );
};

export default ForgotPassword;
