import { Form, Input } from "antd";
import React from "react";

const InputText = (props) => {
  return (
    <Form.Item
      label={
        <span
          style={{
            fontWeight: 500,
            lineHeight: "150%",
            color: "#212529",
          }}
        >
          {props.label}
        </span>
      }
      name={props.name}
      rules={
        props.rules ?? [
          {
            required: true,
            message: `Please input your ${props.message}!`,
          },
        ]
      }
    >
      <Input
        {...props}
        size="large"
        placeholder={`Input your ${props.placeholder}`}
        onChange={(e) => props.onChange(e)}
      />
    </Form.Item>
  );
};

export default InputText;
