import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/reducer";
import masterData from "./masterData/reducer";
import thunk from "redux-thunk";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "masterData"],
};

const rootReducer = combineReducers({
  auth,
  masterData,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
export const persistor = persistStore(store);
