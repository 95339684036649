import React, { useEffect, useRef, useState } from "react";
import { message, Space, Image } from "antd";
import btnEdit from "../../assets/icon/edit-2.svg";
import avatar from "../../assets/image/avatar.png";

const AtomUploadImage = ({
  image,
  setImage,
  previewUrl,
  isEdit,
  setPrefiewProfile,
}) => {
  const uploadImage = useRef(null);

  const [preview, setPreview] = useState();

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/JPEG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    if (isJpgOrPng && isLt2M) {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  useEffect(() => {
    if (!image) {
      if (isEdit) {
        setPreview(previewUrl);
      } else {
        setPreview(undefined);
      }
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, previewUrl, isEdit]);

  return (
    <Space direction="vertical">
      <label
        htmlFor="photoProfile"
        style={{
          fontWeight: 500,
          lineHeight: "150%",
          color: "#212529",
        }}
      >
        <span
          style={{
            color: "#ff4d4f",
            fontFamily: "SimSun, sans-serif",
            lineHeight: 1,
          }}
        >
          *
        </span>
        Photo Profile
      </label>
      <div className="relative">
        <Image
          className="rounded-full mr-6"
          width={70}
          height={70}
          src={preview ?? avatar}
        />
        <div
          className="absolute pointer"
          style={{
            background: "#ffffff",
            borderRadius: "100%",
            width: 30,
            height: 30,
            boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.25)",
            left: 40,
            top: 40,
            display: "flex",
            alignItems: "center",
            padding: "5px",
            gap: "16px",
          }}
          onClick={() => uploadImage.current.click()}
        >
          <img className="cursor-pointer" src={btnEdit} alt="btn" />
        </div>
      </div>
      <input
        ref={uploadImage}
        className="hidden"
        id="photoProfile"
        name="photoProfile"
        type="file"
        onChange={(e) => {
          beforeUpload(e.target.files[0]);
        }}
      />
    </Space>
  );
};

export default AtomUploadImage;
