import React from "react";

const routes = [
  // Not Found Page
  {
    element: React.lazy(() => import("./pages/auth/not-found")),
    path: "/",
  },
  {
    element: React.lazy(() => import("./pages/auth/not-found")),
    path: "/not-found-page",
  },
  {
    element: React.lazy(() => import("./pages/manage-trip/all-trip")),
    path: "/manage-trip",
  },
  {
    element: React.lazy(() => import("./pages/manage-trip/detail-trip")),
    path: "/manage-trip/:id/detail",
  },
  {
    element: React.lazy(() => import("./pages/my-wallet")),
    path: "/my-wallet",
  },
  // MANAGE SERVICES
  {
    element: React.lazy(() => import("./pages/manage-service")),
    path: "/manage-service",
  },
  // DRIVER
  {
    element: React.lazy(() => import("./pages/manage-driver/detail")),
    path: "/manage-driver/:id/detail",
  },
  {
    element: React.lazy(() => import("./pages/manage-driver/list-driver")),
    path: "/list-driver",
  },
  // Dashboard
  {
    element: React.lazy(() => import("./pages/dashboard")),
    path: "/dashboard",
  },
  // PROFILE
  {
    element: React.lazy(() => import("./pages/profile")),
    path: "/profile",
  },
  // Manage Order
  {
    element: React.lazy(() =>
      import("./pages/manage-order/customer-order/index")
    ),
    path: "/customer-order",
  },
  {
    element: React.lazy(() =>
      import("./pages/manage-order/customer-order/detail")
    ),
    path: "/customer-order/:id/detail",
  },
  {
    element: React.lazy(() => import("./pages/manage-order/drop/index")),
    path: "/drop",
  },
  {
    element: React.lazy(() => import("./pages/manage-order/detail-trip")),
    path: "/trip/:id/detail",
  },
];

export default routes;
