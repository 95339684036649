import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { getApp, getApps, initializeApp } from "firebase/app";

import { store } from "../stores/store";
import { setDeviceToken } from "../stores/auth/actions";
import { notification } from "antd";
import { fetchAllNotifications } from "../stores/masterData/actions";

export const getTokenFcm = async () => {
  let app;
  const firebaseApp = initializeApp({
    apiKey: "AIzaSyAnUxChichvkUKKzLaCbZe2lmv77TJ041o",
    authDomain: "superkul-v2.firebaseapp.com",
    projectId: "superkul-v2",
    storageBucket: "superkul-v2.appspot.com",
    messagingSenderId: "408897613208",
    appId: "1:408897613208:web:a6701106c4d54af7130fed",
    measurementId: "G-VH3NB2531H",
  });
  if (!getApps() || getApps().length === 0) {
    app = firebaseApp;
  } else {
    app = getApp();
  }

  const messaging = getMessaging(app);
  const isNotificationSupported = await isSupported();

  if (isNotificationSupported) {
    const status = await Notification.requestPermission();
    if (status && status === "granted") {
      // Get new token from Firebase
      let fcm_token = null;
      if ("serviceWorker" in navigator) {
        const registration = await new Promise((resolve, reject) => {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then(function (registration) {
              resolve(registration);
            })
            .catch(function (err) {
              reject(err);
            });
        });
        fcm_token = await getToken(messaging, {
          vapidKey:
            "BJ-oOAam2awkHIco1EyAkL_707b1G7R5CnmF4HmHLcKkNZjBJRPSWYBfNxk-1gaO8u61_zdwBo6n0marDXYVstk",
          serviceWorkerRegistration: registration,
        });
      }
      console.log(fcm_token, "this is token for notification");
      if (fcm_token) {
        store.dispatch(setDeviceToken(fcm_token));
        onMessage(messaging, (payload) => {
          const { notification: notifFirebase } = payload;
          console.log(notifFirebase, "<<<<<has new notif");
          if (notifFirebase) {
            store.dispatch(fetchAllNotifications());
            notification.open({
              message: notifFirebase.title,
              description: notifFirebase.body,
            });
          }
        });
      }
    }
  }
};
