const AuthTypes = {
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  SET_CURRENT_ROLE: "SET_CURRENT_ROLE",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_LOGIN_STATUS: "SET_LOGIN_STATUS",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_REMEMBER_ME_STATUS: "SET_REMEMBER_ME_STATUS",
  SET_SIDER_VISIBILITY: "SET_SIDER_VISIBILITY",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  SET_LOADING: "SET_LOADING",
  SET_TITLE_BREADCUMBS: "SET_TITLE_BREADCUMBS",
  SET_DEVICE_TOKEN: "SET_DEVICE_TOKEN",
};

export default AuthTypes;
